import { darken, makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../../themes';

type Props = {
  active?: boolean;
  color?: string;
  loading: boolean;
  count: number | string | React.ReactNode;
  label: string;
  label2?: string;
  onClick: (event: React.MouseEvent) => void;
};

const StatusItem: React.FC<Props> = props => {
  const { count, label, label2, onClick } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.status} onClick={onClick} title={label}>
      <div className={classes.count}>{count}</div>
      <div className={classes.label}>{label}</div>
      {label2 && <div className={classes.label}>{label2}</div>}
    </div>
  );
};

StatusItem.defaultProps = {
  active: false,
  color: colors.unassigned[600],
  loading: false,
};

const useStyles = makeStyles(theme => ({
  count: {},
  label: {},
  status: {
    backgroundColor: (props: Props) =>
      props.active ? props.color! : 'inherit',
    borderRightStyle: 'solid',
    borderRightWidth: 4,
    pointerEvents: (props: Props) => (props.loading ? 'none' : 'auto'),
    borderRightColor: (props: Props) => props.color!,
    borderTopRightRadius: (props: Props) => (props.active ? 4 : 0),
    borderBottomRightRadius: (props: Props) => (props.active ? 4 : 0),
    color: (props: Props) =>
      props.active
        ? theme.palette.getContrastText(darken(props.color!, 0.15))
        : 'inherit',
    marginRight: (props: Props) => (props.active ? -4 : 0),
    padding: theme.spacing(1),
    position: 'relative',
    transition: theme.transitions.create(
      ['background-color', 'border-color', 'color', 'width'],
      {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      },
    ),
    zIndex: theme.zIndex.drawer,

    '&:hover': {
      backgroundColor: (props: Props) =>
        props.active ? darken(props.color!, 0.15) : props.color!,
      borderRightColor: (props: Props) =>
        props.active ? darken(props.color!, 0.15) : props.color!,
      color: (props: Props) =>
        theme.palette.getContrastText(
          props.active ? darken(props.color!, 0.15) : props.color!,
        ),
      cursor: 'pointer',
    },

    '& $count': {
      ...theme.typography.h2,
      // fontWeight: theme.typography.fontWeightBold,
      fontWeight: 700,
      lineHeight: 1,
      textAlign: 'right',
    },
    '& $label': {
      fontSize: 9,
      // fontWeight: theme.typography.fontWeightBold,
      fontWeight: 700,
      overflow: 'hidden',
      textAlign: 'right',

      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

export default StatusItem;
