import config from 'doc-mate-store/lib/config';
import {
  LOAD_STATUS,
  TABLE_ROW_FETCH_LIMIT,
  TRACKER_VISIBLE_STATUSES,
} from 'doc-mate-store/lib/constants/load';
import { LoadTableRow } from 'doc-mate-store/lib/models';
import { FetchLoadQuery, toAPI } from 'doc-mate-store/lib/models/LoadQuery';
import { Manifest } from 'doc-mate-store/lib/models/Manifest';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import { defaultRootStore } from 'doc-mate-store/lib/models/Store';
import * as api from 'doc-mate-store/lib/services/api';
import { getSuccess } from 'doc-mate-store/lib/utils/models';
import { observable } from 'mobx';
import {
  _async,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  prop_mapArray,
  Ref,
  registerRootStore,
} from 'mobx-keystone';
import qs from 'qs';
import ColumnFilters from './ColumnFilter';
import PresetTables from './PresetTables';
import { SavedTable } from './SavedTable';
import { loadTableRowRef } from './WebLoad';

@model('doc-mate-web/WebStore')
export class WebStore extends Model({
  loaded: prop<boolean>(false),
  manifestCount: prop<number>(0),
  filterDrawerOpen: prop<boolean>(false),
  presetTables: prop<PresetTables | undefined>(),
  savedTables: prop_mapArray(() => new Map<number, SavedTable>()),
  visibleLoadIds: prop<Ref<LoadTableRow>[]>(() => []),
  columnFilters: prop<ColumnFilters | undefined>(),
  selectedStatus: prop<string>(''),
}) {
  convertManifestCountToManifestNumber(count: number): string {
    const number = count % 10000;
    const numberStr = String(number).padStart(4, '0');
    const lowerChrInd = Math.floor(count / 10000);
    const lowerChr = String.fromCharCode(65 + (lowerChrInd % 26));
    const upperChrInd = Math.floor(lowerChrInd / 26);
    const upperChr = String.fromCharCode(65 + (upperChrInd % 26));

    return `${upperChr}${lowerChr}${numberStr}`;
  }
  get nextManifestNumber(): string {
    return this.convertManifestCountToManifestNumber(this.manifestCount);
  }

  @observable
  loading = false;

  @modelFlow
  load = _async(function*(this: WebStore) {
    this.loaded = false;
    this.selectedStatus = 'active';
    yield defaultRootStore.load();
    if (defaultRootStore.token) {
      yield this.fetchPresetTable();
      yield this.fetchSavedTables();
    }
    this.loaded = true;
  });

  @modelFlow
  logout = _async(function*(this: WebStore) {
    yield defaultRootStore.logout();
  });

  @modelAction
  setFilterDrawer(state: boolean) {
    this.filterDrawerOpen = state;
  }

  @modelAction
  setSelectedStatus(status: string) {
    this.selectedStatus = status;
  }

  @modelAction
  clearVisibleIds() {
    this.visibleLoadIds.splice(0, this.visibleLoadIds.length);
  }

  @modelFlow
  fetchLoads = _async(function*(
    this: WebStore,
    query: Partial<FetchLoadQuery>,
    statuses: LOAD_STATUS[],
    offset?: string,
  ) {
    this.loading = true;

    // const {
    //   count,
    // }: // visibleIds,
    // {
    //   count: number;
    //   visibleIds: number[];
    //   // next: string | null;
    // } = yield defaultRootStore.fetchVisibleTableRows(
    //   query,
    //   statuses,
    //   undefined, //No need for URL
    //   offset,
    // );

    // let cachedRows = [];
    // let itr = 0;
    // let shouldRetrieveTableRow = false;

    // while (itr < visibleIds.length) {
    //   if (defaultRootStore.loadTableRows.has(`${visibleIds[itr]}`)) {
    //     const loadRef = loadTableRowRef(`${visibleIds[itr]}`);

    //     cachedRows.push(loadRef);
    //   } else {
    //     shouldRetrieveTableRow = true;
    //     break;
    //   }
    //   itr += 1;
    // }

    // if (url) {
    //   this.visibleLoadIds = this.visibleLoadIds.concat(cachedRows);
    // } else {
    //   this.visibleLoadIds = cachedRows;
    // }

    // if (shouldRetrieveTableRow) {

    const fetchTableRowUrl = `${config.urls.loads}table/?${qs.stringify(
      toAPI({
        limit: `${TABLE_ROW_FETCH_LIMIT}`,
        // offset: `${this.visibleLoadIds.length}`,
        offset: offset ? offset : '0',
        ...query,
        status__in: statuses,
      }),
    )}`;

    const {
      count,
      loads,
    }: // next
    {
      count: number;
      loads: LoadTableRow[];
      // next: string | null;
    } = yield defaultRootStore.fetchLoadTableRows(
      query,
      statuses,
      fetchTableRowUrl,
    );

    const loadIds = loads.map(load => loadTableRowRef(`${load.id}`));
    this.visibleLoadIds = this.visibleLoadIds.concat(loadIds);
    // this.loading = false;
    // return `${config.urls.loads}visible/?${qs.stringify(
    //   toAPI({
    //     limit: `${VISIBLE_ID_FETCH_LIMIT}`,
    //     offset: `${this.visibleLoadIds.length}`,
    //     status__in: statuses,
    //     ...query,
    //   }),
    // )}`;
    // }
    // else {
    //   this.visibleLoadIds = this.visibleLoadIds.concat(cachedRows);
    // }
    this.loading = false;
    return count;
  });

  @modelFlow
  fetchPresetTable = _async(function*(this: WebStore) {
    const { token } = defaultRootStore;
    let result:
      | (PresetTables & { manifest_count: number })
      | undefined = undefined;

    try {
      ({
        response: { entities: result },
      } = yield api.fetchLoadCounts(
        token!,
        toAPI({
          status__in: TRACKER_VISIBLE_STATUSES,
          // shipper_or_receiver__username: me!.current.username,
        }),
      ));
    } catch (error) {
      console.log('[DEBUG] error', error);
    }

    if (result) {
      const { manifest_count, ...counts } = result;
      this.manifestCount = manifest_count;
      this.presetTables = counts as PresetTables;
    }
  });

  @modelFlow
  updatePresetTable = _async(function*(
    this: WebStore,
    columnOrdering: string[],
  ) {
    const { token } = defaultRootStore;
    let counts: PresetTables | undefined = undefined;

    try {
      ({
        response: { entities: counts },
      } = yield api.updateLoadCounts(
        token!,
        toAPI({
          status__in: TRACKER_VISIBLE_STATUSES,
          // shipper_or_receiver__username: me!.current.username,
        }),
        { preset: columnOrdering },
      ));
    } catch (error) {
      console.log('[DEBUG] error', error);
    }

    this.presetTables = counts as PresetTables;
  });

  @modelAction
  setSavedTable(data: SavedTable) {
    const savedTable = ({
      ...data,
    } as unknown) as ModelInstanceCreationData<SavedTable>;
    try {
      const newSavedTable = new SavedTable(savedTable);
      this.savedTables.set(savedTable.id, newSavedTable);
    } catch (error) {
      console.log('[DEBUG] error', error);
    }
  }

  @modelFlow
  fetchSavedTables = _async(function*(this: WebStore) {
    const { token } = defaultRootStore;
    let savedTables: SavedTable[] = [];

    try {
      ({
        response: { entities: savedTables },
      } = yield api.fetchSavedTables(token!));
    } catch (error) {
      console.log('[error]', error);
      return;
    }

    savedTables.forEach(savedTable => this.setSavedTable(savedTable));
  });

  @modelFlow
  fetchSavedTable = _async(function*(this: WebStore, savedTableId: number) {
    const { token } = defaultRootStore;
    let savedTable: SavedTable;

    try {
      ({
        response: { entities: savedTable },
      } = yield api.fetchSavedTable(token!, savedTableId));
    } catch (error) {
      console.log('[error]', error);
      return;
    }

    this.setSavedTable(savedTable);
    return savedTable;
  });

  @modelFlow
  createSavedTable = _async(function*(
    this: WebStore,
    defaultColumnOrdering?: string[],
  ) {
    const { token } = defaultRootStore;
    let savedTable: SavedTable;

    try {
      ({
        response: { entities: savedTable },
      } = yield api.createSavedTable(token!, {
        name: 'New Table',
        column_ordering: defaultColumnOrdering,
      }));
    } catch (error) {
      console.log('[error]', error);
      throw error;
    }

    this.setSavedTable(savedTable);
    return savedTable;
  });

  @modelFlow
  deleteSavedTable = _async(function*(this: WebStore, savedTable: SavedTable) {
    const { token } = defaultRootStore;

    this.savedTables.delete(savedTable.id);

    try {
      yield api.deleteSavedTable(token!, savedTable.id);
    } catch (error) {
      console.log('[error]', error);
      this.savedTables.set(savedTable.id, savedTable);
      return;
    }
  });

  @modelFlow
  createLoads = _async(function*(this: WebStore, data: any) {
    const result = yield defaultRootStore.createManifestLoads(data);
    const { ok } = result;
    if (!ok) {
      return result;
    } else {
      const { loads }: { loads: OrderLeg[] } = result;
      const newLoadIds = loads.map(load => load.id);
      const {
        loads: rows,
      }: {
        loads: LoadTableRow[];
        next: string | null;
      } = yield defaultRootStore.fetchLoadTableRows({ id__in: newLoadIds }, []);
      if (
        this.selectedStatus === 'all' ||
        this.selectedStatus === 'assignment_unassigned'
      ) {
        const newRowIds = rows.map(load => loadTableRowRef(`${load.id}`));
        this.visibleLoadIds.unshift(...newRowIds);
      }

      yield this.fetchPresetTable();
      return getSuccess();
    }
  });
  @modelFlow
  updateLoads = _async(function*(this: WebStore, data: any) {
    const result = yield defaultRootStore.updateManifestLoads(data);
    const { ok } = result;
    if (!ok) {
      return result;
    } else {
      const {
        loads,
        manifest,
      }: { loads: OrderLeg[]; manifest: Manifest } = result;
      const newLoadIds = loads.map(load => load.id);
      const ordersInManifest = Array.from(
        defaultRootStore.loads.values(),
      ).filter(order => order.manifestId === manifest.id);

      const {
        loads: rows,
      }: {
        loads: LoadTableRow[];
        next: string | null;
      } = yield defaultRootStore.fetchLoadTableRows({ id__in: newLoadIds }, []);
      const newRowIds = rows.map(load => loadTableRowRef(`${load.id}`));
      this.visibleLoadIds = this.visibleLoadIds.filter(visibleLeg => {
        let retain = true;
        ordersInManifest.forEach(order => {
          if (order.id === visibleLeg.current.orderId) {
            retain = false;
          }
        });
        return retain;
      });

      this.visibleLoadIds.unshift(...newRowIds);
      yield this.fetchPresetTable();
      return getSuccess();
    }
  });
}

export const createWebStore = () => {
  const webStore = new WebStore({
    columnFilters: new ColumnFilters({ filters: {} }),
  });
  return webStore;
};

export const defaultWebStore = createWebStore();
registerRootStore(defaultWebStore);
