"use strict";
// function required(envName: string) {
//   if (process.env[envName]) {
//     return process.env[envName]!;
//   }
//   console.error('Environment:', process.env);
//   throw new Error(
//     `The environment variable '${envName}' is required to run and build this project. More information available in the console.`,
//   );
// }
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    debug: false,
    urls: {
        base: process.env.REACT_APP_BASE_URL,
        web: process.env.REACT_APP_WEB_URL,
        api: process.env.REACT_APP_API_URL,
        signup: 'auth/registration/',
        login: 'auth/login/',
        loginWithFacebook: 'auth/facebook/',
        loginWithTwitter: 'auth/twitter/',
        loginWithGoogle: 'auth/google/',
        connectFacebook: 'auth/facebook/connect/',
        connectTwitter: 'auth/twitter/connect/',
        connectGoogle: 'auth/google/connect/',
        logout: 'auth/logout/',
        logoutall: 'auth/logoutall/',
        firebaseToken: 'auth/firebase/',
        changePassword: 'auth/password/change/',
        resetPassword: 'auth/password_reset/',
        resetPasswordConfirm: 'auth/password_reset/confirm/',
        loads: 'loads/',
        loadDocuments: 'loads/documents/',
        loadDocumentPhotos: 'loads/document-photos/',
        manifests: 'loads/manifests/',
        driverSchedules: 'driverschedule/',
        activeDriverSchedule: 'driverschedule/driver_get_active_schedule/',
        completedDriverSchedule: 'driverschedule/driver_get_completed_schedule/',
        proposedDriverSchedule: 'driverschedule/driver_get_proposed_schedule/',
        driverScheduleOrders: 'driverschedule/driver_orders_in_schedule/',
        driverScheduleOrderLegs: 'driverschedule/driver_order_legs_in_schedule/',
        driverCompletedOrderLegs: 'driverschedule/driver_completed_order_legs_in_schedule/',
        driverAcceptSchedule: 'driverschedule/driver_accept_schedule/',
        scheduleRow: 'driverschedule/schedulerow/',
        socialAccounts: 'auth/socialaccounts/',
        trucks: 'loads/trucks/',
        twitterRequestToken: 'auth/twitter/request-token/',
        twitterAccessToken: 'auth/twitter/access-token/',
        vehicleTypes: 'loads/vehicle-types/',
        locations: 'locations/',
        user: 'users/',
        organizations: 'users/organizations/',
        notifications: 'notifications/',
        ws: process.env.REACT_APP_WS_URL,
        privacyPolicy: "".concat(process.env.REACT_APP_WEB_URL, "privacypolicy/"),
    },
    tokenKey: process.env.REACT_APP_FIREBASE_TOKEN_KEY || 'TOKEN_KEY',
    firebaseConfig: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
    googleMapsApiKey: {
        web: process.env.REACT_APP_WEB_GOOGLE_MAPS_API_KEY,
        ios: process.env.REACT_APP_IOS_GOOGLE_MAPS_API_KEY,
        android: process.env.REACT_APP_ANDROID_GOOGLE_MAPS_API_KEY,
    },
    googleSignInConfig: {
        iosClientId: process.env.REACT_APP_GOOGLE_SIGN_IN_IOS_CLIENT_ID,
        androidClientId: process.env.REACT_APP_GOOGLE_SIGN_IN_ANDROID_CLIENT_ID,
        iosStandaloneAppClientId: process.env.REACT_APP_GOOGLE_SIGN_IN_IOS_STANDALONE_CLIENT_ID,
        androidStandaloneAppClientId: process.env.REACT_APP_GOOGLE_SIGN_IN_ANDROID_STANDALONE_CLIENT_ID,
        clientId: process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID,
    },
    dynamsoftAPiKey: process.env.REACT_APP_DYNAMSOFT_API_KEY,
};
exports.default = config;
