import { makeStyles, Typography, useTheme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  ON_ASSIGNMENT_STATUS,
  ON_ASSIGNMENT_STATUS_API,
  ON_ASSIGNMENT_STATUS_LIST,
  ON_ASSIGNMENT_STATUS_SHORT_TEXT,
  ON_TIME_STATUS,
  ON_TIME_STATUS_API,
  ON_TIME_STATUS_DROPOFF,
  ON_TIME_STATUS_PICKUP,
  ON_TIME_STATUS_SHORT_TEXT,
} from 'doc-mate-store/lib/constants/load';
import { User } from 'doc-mate-store/lib/models';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DataTableContext } from '../../contexts';
import { SavedTable } from '../../models/SavedTable';
import { colors, onTimeStatusColors } from '../../themes';
import StatusItem from './StatusItem';

type Props = {
  me?: User;
  loading: boolean;
};

const TrackerStatusBar: React.FC<Props> = ({ me, loading }) => {
  const theme = useTheme();
  const {
    preset,
    presetTables,
    savedTables,
    savedTable,
    setValues,
    createSavedTable,
  } = useContext(DataTableContext);
  const classes = useStyles({ me, loading });

  const statusColors = onTimeStatusColors(theme);

  const ref = useRef<HTMLDivElement>(null);
  const [showTop, setShowTop] = useState(false);
  const [showBottom, setShowBottom] = useState(false);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const { offsetHeight, scrollTop, scrollHeight } = ref.current;
      setShowTop(scrollTop > 0);
      setShowBottom(scrollTop + offsetHeight < scrollHeight);
    }
  }, []);

  const handleScrollUp = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({
        top: -ref.current.offsetHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleScrollDown = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({
        top: ref.current.offsetHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    let r: any = undefined;
    if (ref.current) {
      handleScroll();
      window.addEventListener('resize', handleScroll);
      ref.current.addEventListener('scroll', handleScroll);
      r = ref.current;
    }
    return () => {
      if (r) {
        window.removeEventListener('resize', handleScroll);
        r.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleStatusItemClick = (
    onTimeStatus:
      | ON_ASSIGNMENT_STATUS
      | ON_TIME_STATUS
      | 'all'
      | 'active'
      | 'delivered'
      | 'completed'
      | 'rejected',
    isOnAssignment?: boolean,
  ) => {
    const apiOnTimeStatus = ON_TIME_STATUS_API[onTimeStatus as ON_TIME_STATUS];
    const apiOnAssignmentStatus =
      ON_ASSIGNMENT_STATUS_API[onTimeStatus as ON_ASSIGNMENT_STATUS];

    if (isOnAssignment && apiOnAssignmentStatus) {
      setValues(undefined, apiOnAssignmentStatus);
    } else if (apiOnTimeStatus) {
      setValues(undefined, apiOnTimeStatus);
    } else {
      setValues(undefined, onTimeStatus as string);
    }
  };

  const handleSavedTableClick = (savedTable: SavedTable) => {
    setValues(savedTable);
  };

  const handleNewSavedTableClick = async () => {
    const savedTable = await createSavedTable();
    if (savedTable) {
      handleSavedTableClick(savedTable);
    }
  };

  return (
    <div ref={ref} className={classes.root}>
      <div
        className={classes.scrollerTop}
        onClick={handleScrollUp}
        style={{
          display: showTop ? 'inherit' : 'none',
        }}
      >
        <KeyboardArrowUpIcon fontSize="large" />
      </div>

      <div className={classes.container}>
        <div className={classes.sectionWrapper}>
          <StatusItem
            active={!savedTable && preset === 'all'}
            color={statusColors[ON_TIME_STATUS.UNKNOWN]}
            loading={loading}
            count={presetTables ? presetTables.all.results : 0}
            label={'All'}
            onClick={() => handleStatusItemClick('all')}
          />
          <StatusItem
            active={!savedTable && preset === 'active'}
            color={statusColors[ON_TIME_STATUS.UNKNOWN]}
            loading={loading}
            count={presetTables ? presetTables.active.results : 0}
            label={'Active'}
            onClick={() => handleStatusItemClick('active')}
          />
          <StatusItem
            active={!savedTable && preset === 'delivered'}
            color={statusColors[ON_TIME_STATUS.UNKNOWN]}
            loading={loading}
            count={presetTables ? presetTables.delivered.results : 0}
            label={'Delivered'}
            onClick={() => handleStatusItemClick('delivered')}
          />
          <StatusItem
            active={!savedTable && preset === 'completed'}
            color={statusColors[ON_TIME_STATUS.UNKNOWN]}
            loading={loading}
            count={presetTables ? presetTables.completed.results : 0}
            label={'Completed'}
            onClick={() => handleStatusItemClick('completed')}
          />

          <StatusItem
            active={!savedTable && preset === 'rejected'}
            color={statusColors[ON_TIME_STATUS.UNKNOWN]}
            loading={loading}
            count={presetTables ? presetTables.rejected.results : 0}
            label={`Driver`}
            label2="Rejected"
            onClick={() => handleStatusItemClick('rejected')}
          />
        </div>

        {me && (me.isDispatcher || me.isBroker) && (
          <div key="onAssignment" className={classes.sectionWrapper}>
            <Typography component="h2" variant="h6" className={classes.section}>
              Assignment
            </Typography>
            {ON_ASSIGNMENT_STATUS_LIST.map(oas => (
              <StatusItem
                key={oas}
                active={preset === ON_ASSIGNMENT_STATUS_API[oas]}
                color={statusColors[oas]}
                loading={loading}
                count={
                  presetTables
                    ? presetTables[ON_ASSIGNMENT_STATUS_API[oas]].results
                    : 0
                }
                label={ON_ASSIGNMENT_STATUS_SHORT_TEXT[oas]}
                onClick={() => handleStatusItemClick(oas, true)}
              />
            ))}
          </div>
        )}

        {[
          [ON_TIME_STATUS_PICKUP, 'Pick-up'],
          [ON_TIME_STATUS_DROPOFF, 'Drop-off'],
        ].map(l => (
          <div key={l.toString()} className={classes.sectionWrapper}>
            <Typography component="h2" variant="h6" className={classes.section}>
              {l[1]}
            </Typography>
            {((l[0] as any) as ON_TIME_STATUS[]).map(ots => (
              <StatusItem
                key={ots}
                active={preset === ON_TIME_STATUS_API[ots]}
                color={statusColors[ots]}
                loading={loading}
                count={
                  presetTables
                    ? presetTables[ON_TIME_STATUS_API[ots]].results
                    : 0
                }
                label={ON_TIME_STATUS_SHORT_TEXT[ots]}
                onClick={() => handleStatusItemClick(ots)}
              />
            ))}
          </div>
        ))}

        <div className={classes.sectionWrapper}>
          <Typography component="h2" variant="h6" className={classes.section}>
            Saved Tables
          </Typography>

          {Array.from(savedTables.values()).map(st => (
            <StatusItem
              key={`savedTable-${st.id}`}
              active={savedTable && savedTable.id === st.id}
              color={statusColors[ON_TIME_STATUS.UNKNOWN]}
              loading={loading}
              count={st.results}
              label={st.name}
              onClick={() => handleSavedTableClick(st)}
            />
          ))}

          <StatusItem
            active={false}
            color={statusColors[ON_TIME_STATUS.UNKNOWN]}
            count="+"
            loading={loading}
            label="New Table"
            onClick={handleNewSavedTableClick}
          />
        </div>
      </div>

      <div
        className={classes.scrollerBottom}
        onClick={handleScrollDown}
        style={{
          display: showBottom ? 'inherit' : 'none',
        }}
      >
        <KeyboardArrowDownIcon fontSize="large" />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(10.5),
    background: 'transparent',
    minWidth: theme.spacing(10.5),
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    zIndex: theme.zIndex.appBar * 2,
  },
  container: {
    minHeight: '100%',
    width: theme.spacing(10),
    background: '#e6e6e7',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[400],

    '& $sectionWrapper:nth-child(2n+1)': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  sectionWrapper: {
    marginRight: -1,
    opacity: (props: Props) => (props.loading ? 0.5 : 1),
  },
  section: {
    color: colors.primary[900],
    cursor: 'default',
    fontSize: 12,
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    padding: theme.spacing(1, 1, 1, 0),
    userSelect: 'none',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  scrollerTop: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'fixed',
    left: 0,
    background:
      'linear-gradient(180deg, #ebebec 0%, rgba(235, 235, 236, 0) 70%);',
    width: theme.spacing(10),
    textAlign: 'center',
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    zIndex: 900000,

    '& > *': {
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
    },

    '&:hover': {
      background: colors.primary[600],
      color: theme.palette.getContrastText(colors.primary[600]),
    },

    '&:hover > *': {
      transform: 'translateY(-8px)',
    },
  },
  scrollerBottom: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'fixed',
    bottom: 0,
    left: 0,
    background:
      'linear-gradient(180deg, rgba(235, 235, 236, 0) 0%, #ebebec 70%);',
    width: theme.spacing(10),
    textAlign: 'center',
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 700,
    zIndex: 900000,

    '& > *': {
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
    },

    '&:hover': {
      background: colors.primary[600],
      color: theme.palette.getContrastText(colors.primary[600]),
    },

    '&:hover > *': {
      transform: 'translateY(8px)',
    },
  },
}));

export default observer(TrackerStatusBar);
