import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  alpha,
  makeStyles,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Row as RTRow } from 'react-table';
import { DataTableContext } from '../../contexts';
type Props = {
  fetching: boolean;
  getRowProps?(row: RTRow): {};
  // tableProps: TableInstance<{}>;
};

const DataTable: React.FC<Props> = ({ fetching, getRowProps }) => {
  const classes = useStyles();
  const { tableProps } = useContext(DataTableContext);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { columnResizing },
  } = tableProps;

  let headerGroupWidth = '0px';

  useEffect(() => {
    if (columnResizing.isResizingColumn === null) {
      const columnWidths = JSON.parse(
        localStorage.getItem('columnWidths') || '{}',
      );

      Object.entries(columnResizing.columnWidths).forEach(([key, value]) => {
        columnWidths[key] = value;
      });

      localStorage.setItem('columnWidths', JSON.stringify(columnWidths));
    }
  }, [columnResizing]);

  return (
    <Table stickyHeader {...getTableProps()} className={classes.root}>
      <TableHead className={classes.thead}>
        {headerGroups.map(headerGroup => {
          const { style } = headerGroup.getHeaderGroupProps();
          headerGroupWidth = style!.width as string;
          return (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={{
                width: style!.width,
              }}
              className={[classes.theadrow, classes.sticky].join(' ')}
            >
              {headerGroup.headers.map(column => {
                return (
                  <TableCell
                    {...column.getHeaderProps()}
                    className={classes.theadTd}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span>
                            {' '}
                            <ArrowDownwardIcon
                              className={classes.sortIcon}
                              fontSize="small"
                            />
                          </span>
                        ) : (
                          <span>
                            {' '}
                            <ArrowUpwardIcon
                              className={classes.sortIcon}
                              fontSize="small"
                            />
                          </span>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                    {column.render('Header') !== ' ' && (
                      <div {...column.getResizerProps()} className="resizer" />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          const additional = getRowProps ? [getRowProps(row)] : [];
          return (
            <TableRow
              {...row.getRowProps(additional)}
              className={[
                classes.row,
                index % 2 ? classes.evenRow : classes.oddRow,
              ].join(' ')}
            >
              {row.cells.map(cell => {
                return (
                  <TableCell
                    {...cell.getCellProps(additional)}
                    className={[
                      classes.tbodyTd,
                      cell.render('Header') === 'DRVR'
                        ? 'driver-select-cell'
                        : cell.render('Header') === ' '
                        ? 'link-cell'
                        : '',
                    ].join(' ')}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
        {!rows.length && (
          <TableRow className={classes.row} style={{ width: headerGroupWidth }}>
            <TableCell
              className={classes.tbodyTd}
              colSpan={visibleColumns.length}
            ></TableCell>
          </TableRow>
        )}

        {fetching && (
          <TableRow className={classes.row}>
            <TableCell
              className={classes.tbodyTd}
              colSpan={visibleColumns.length}
              variant="footer"
            >
              <CircularProgress size={30} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    width: 'auto',
  },
  sortIcon: {
    float: 'right',
    verticalAlign: 'middle',
  },
  thead: {},
  theadrow: {
    position: '-webkit-sticky',
  },
  sticky: {
    top: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
  },
  theadTd: {
    textTransform: 'uppercase',
    borderWidth: 1,
    height: 70,

    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderBottomColor: theme.palette.grey[600],
    padding: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    '& .resizer': {
      display: 'inline-block',
      opacity: 1,
      width: 2.5,
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(50%)',
      zIndex: '1',
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        width: 5,
        backgroundColor: '#4D85A7',
      },
    },
  },
  evenRow: {},
  oddRow: {
    backgroundColor: alpha(theme.palette.grey[400], 0.15),
  },
  row: {
    display: 'flex',
    cursor: 'pointer',
    height: 40,
    transition: '0.1s background-color',
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[500], 0.3),
    },

    '& td': {
      borderBottom: 0,
    },
  },
  tbodyTd: {
    padding: theme.spacing(0.25),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    '&.driver-select-cell': {
      overflow: 'visible',
    },
    '&.link-cell': {
      overflow: 'visible',
    },
  },
}));

export default React.memo(observer(DataTable));
