import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  BILL_TO_TEXT,
  LOAD_STOP_TYPE,
  MODE_OPTION_TEXT,
} from 'doc-mate-store/lib/constants/load';
import { useStores } from 'doc-mate-store/lib/hooks';
import { LoadType, LoadUnit, Location } from 'doc-mate-store/lib/models';
import { cloneDeep, omit } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWebStores } from '../../hooks';
import { colors } from '../../themes';
import { AutocompleteFieldOption } from '../AutocompleteField/types';
import {
  initialAccessorialState,
  initialAccessorialValue,
  initialBillingState,
  initialLoadStopState,
  initialManifestData,
  initialOrderLegsState,
  initialOrderState,
  initialOrderStateBroker,
} from './constants';
import ManifestBuilderForm from './Form';
import {
  AccessorialFormData,
  BillingFormData,
  ManifestFormData,
  OrderFormData,
  OrderLegFormData,
} from './types';
import {
  getInitialAccessorialFormState,
  getInitialBillingFormState,
  getInitialManifestFormState,
  getInitialOrderFormState,
  getInitialOrderLegFormState,
  sleep,
} from './utils';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ManifestBuilderDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const [formOpen, setFormOpen] = useState<boolean>(open);
  const [isLoadLoading, setIsLoadLoading] = useState<boolean>(false);

  // const [loadStopData, setLoadStopData] = useState(initialLoadStopState);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    loadTypes: loadTypes_,
    loadUnits: loadUnits_,
    locations: locations_,
    updateManifestId,
    copyManifestId,
    me,
  } = rootStore;
  const [loadData, setLoadData] = useState(
    cloneDeep(
      me && me.current && me.current.isBroker
        ? initialOrderStateBroker
        : initialOrderState,
    ),
  );
  const [manifestData, setManifestData] = useState(initialManifestData);
  const [billingData, setBillingData] = useState(initialBillingState);
  const [orderLegData, setOrderLegData] = useState(initialOrderLegsState); //eslint-disable-line @typescript-eslint/no-unused-vars
  const [accessorialData, setAccessorialData] = useState(
    initialAccessorialState,
  );
  const { nextManifestNumber } = webStore;

  const loadTypes: LoadType[] = Array.from(loadTypes_.values());
  const loadTypesJ = JSON.stringify(loadTypes);
  const loadTypeChoices = useMemo<string[]>(
    () => loadTypes.map(loadType => loadType.name),
    [loadTypesJ], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const loadUnits: LoadUnit[] = Array.from(loadUnits_.values());
  const loadUnitsJ = JSON.stringify(loadUnits);
  const quantityUnitChoices = useMemo<string[]>(
    () =>
      loadUnits
        .filter(unit => unit.dimension === 'quantity')
        .map(unit => unit.name),
    [loadUnitsJ], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const weightUnitChoices = useMemo<string[]>(
    () =>
      loadUnits
        .filter(unit => unit.dimension === 'weight')
        .map(unit => unit.name),
    [loadUnitsJ], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const distanceUnitChoices = useMemo<string[]>(
    () =>
      loadUnits
        .filter(unit => unit.dimension === 'distance')
        .map(unit => unit.name),
    [loadUnitsJ], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const locations: Location[] = Array.from(locations_.values());
  const locationsJ = JSON.stringify(locations);

  const locationChoices = useMemo<AutocompleteFieldOption[]>(
    () =>
      locations
        .filter(loc =>
          me &&
          me.current.isBroker &&
          me.current.organization &&
          me.current.organization.current &&
          me.current.organization.current.name !== 'Four Star Transportation'
            ? loc.name === 'Four Star Services'
            : loc.isFourStar || loc.name === 'Four Star Services',
        )
        .map(loc => ({ name: loc.name, value: loc.getRefId() })),
    [locationsJ], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const loads = useMemo(() => Array.from(Object.values(loadData)), [loadData]);
  // const loadStops = Array.from(Object.values(loadStopData));
  const orderLegs = useMemo(() => Array.from(Object.values(orderLegData)), [
    orderLegData,
  ]);
  const billings = useMemo(() => Array.from(Object.values(billingData)), [
    billingData,
  ]);
  const accessorials = useMemo(
    () => Array.from(Object.values(accessorialData)),
    [accessorialData],
  );

  useEffect(() => {
    if (me && me.current && me.current.isBroker) {
      manifestData.revenueTerminal = locationChoices[0];
    }
  }, [locationChoices, manifestData, me]);

  useEffect(() => {
    (async () => {
      if (updateManifestId || copyManifestId) {
        setIsLoadLoading(true);
        const manifestId = updateManifestId ? updateManifestId : copyManifestId;
        const result = await rootStore.fetchManifest(manifestId!);
        if (
          result &&
          ((updateManifestId && rootStore.updatingManifest) ||
            (copyManifestId && rootStore.copyingManifest))
        ) {
          setFormOpen(true);
          const { manifest, orders, loads, billings, accessorials } = result;

          let manifestFormData: ManifestFormData | null = null;
          let orderFormData: { [id: string]: OrderFormData } | null = null;
          let orderLegFormData: {
            [id: string]: OrderLegFormData;
          } | null = null;
          let billingFormData: {
            [id: string]: BillingFormData;
          } | null = null;
          let accessorialFormData: {
            [id: string]: AccessorialFormData;
          } | null = null;
          while (
            !manifestFormData ||
            !orderFormData ||
            !orderLegFormData ||
            !billingFormData
          ) {
            await sleep(3000);
            manifestFormData = getInitialManifestFormState(manifest!);
            orderFormData = getInitialOrderFormState(
              orders!,
              !!updateManifestId,
            );
            orderLegFormData = getInitialOrderLegFormState(
              loads!,
              !!updateManifestId,
            );
            billingFormData = getInitialBillingFormState(billings!);
            accessorialFormData = getInitialAccessorialFormState(accessorials!);
          }

          setManifestData(manifestFormData);
          setLoadData(orderFormData);
          setOrderLegData(orderLegFormData);
          setBillingData(billingFormData);

          if (accessorialFormData) {
            setAccessorialData(accessorialFormData);
          }

          setIsLoadLoading(false);
        }
      }
    })();
  }, [rootStore, updateManifestId, copyManifestId]);

  const handleReset = useCallback(() => {
    setManifestData(initialManifestData);
    setLoadData(
      cloneDeep(
        cloneDeep(
          me && me.current && me.current.isBroker
            ? initialOrderStateBroker
            : initialOrderState,
        ),
      ),
    );
    setOrderLegData(initialOrderLegsState);
    setBillingData(initialBillingState);
    setAccessorialData(initialAccessorialState);
  }, [me]);

  const handleFormClose = useCallback(
    (event: any, addAnother: boolean = false) => {
      if (!addAnother) {
        setIsLoadLoading(false);
        setFormOpen(false);
        handleReset();
        rootStore.resetUpdateManfestId();
        rootStore.resetCopyManifestId();
        onClose();
        return;
      }
    },
    [handleReset, onClose, rootStore],
  );

  const handleManifestChange = useCallback((name: string, value: any) => {
    setManifestData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleOnLoadedSameTrailerChange = useCallback(
    (orderW: string) => {
      setLoadData(prevState => {
        let stopData = prevState[orderW].stops!;
        const orderOne = Object.values(prevState).find(
          order => order.orderNumber === '1',
        );
        if (!orderOne) {
          return prevState;
        }

        const shipperIndex = prevState[orderW].stops!.findIndex(
          stop => stop.stopNumber === 1,
        );
        const orderOneShipperIndex = prevState[
          `${orderOne.idW}`
        ].stops!.findIndex(stop => stop.stopNumber === 1);
        // Get shipper stop of order 1
        const orderOneShipper =
          loadData &&
          loadData[`${orderOne.idW}`] &&
          loadData[`${orderOne.idW}`].stops &&
          loadData[`${orderOne.idW}`].stops![orderOneShipperIndex];

        const newShipper = {
          ...stopData[shipperIndex],
          ...orderOneShipper,
        };
        stopData[shipperIndex] = newShipper;

        return {
          ...prevState,
          [orderW]: {
            ...prevState[orderW],
            stops: [...stopData],
          },
        };
      });
    },
    [loadData],
  );

  const handleOrderChange = useCallback(
    (idW: string, name: string, value: any) => {
      const prevData = loads.find(load => load.idW === idW);
      if (name === 'loadedOnSameTrailer' && value === true) {
        handleOnLoadedSameTrailerChange(idW);
      }
      if (prevData) {
        setLoadData(prevState => ({
          ...prevState,
          [idW]: {
            ...prevData,
            [name]: value,
          },
        }));
      }
    },
    [handleOnLoadedSameTrailerChange, loads],
  );

  const handleAddOrder = useCallback(() => {
    let newKeyOrder = 1;
    setLoadData(prevState => {
      const stateKeys = Object.keys(prevState);

      if (stateKeys.length > 0) {
        newKeyOrder = parseInt(stateKeys[stateKeys.length - 1], 10) + 1;
      }
      setOrderLegData(prevState => {
        const stateKeys = Object.keys(prevState);
        let newKeyLeg = 1;
        if (stateKeys.length > 0) {
          newKeyLeg = parseInt(stateKeys[stateKeys.length - 1], 10) + 1;
        }
        setBillingData(prevState => {
          const stateKeys = Object.keys(prevState);
          let newKey = 1;
          if (stateKeys.length > 0) {
            newKey = parseInt(stateKeys[stateKeys.length - 1], 10) + 1;
          }
          return {
            ...prevState,
            [newKey]: {
              ...initialBillingState['1'],
              idW: newKey.toString(),
              legW: newKeyLeg.toString(),
              percentage: '',
            },
          };
        });
        return {
          ...prevState,
          [newKeyLeg]: {
            ...initialOrderLegsState[1],
            idW: newKeyLeg.toString(),
            orderW: newKeyOrder.toString(),
          },
        };
      });
      return {
        ...prevState,
        [newKeyOrder]: {
          ...cloneDeep(initialOrderState['1']),
          idW: newKeyOrder.toString(),
          orderNumber: (stateKeys.length + 1).toString(),
          loadedOnSameTrailer: true,
        },
      };
    });

    handleOnLoadedSameTrailerChange(newKeyOrder.toString());
  }, [handleOnLoadedSameTrailerChange]);

  const handleLoadStopChange = useCallback(
    (orderW: string, id: string, name: string, value: any) => {
      setLoadData(prevState => {
        const order = prevState[orderW];
        let result = {};
        if (order.orderNumber === '1' && id === '1') {
          Object.values(prevState)
            .filter(order => order.loadedOnSameTrailer)
            .map(order => {
              const stops = prevState[order.idW!].stops!;
              const index = stops.findIndex(stop => stop.stopNumber === 1);
              stops[index] = {
                ...stops[index],
                [name]: value,
              };
              result = {
                ...result,
                [order.idW!]: {
                  ...prevState[order.idW!],
                  stops: stops,
                },
              };
              return result;
            });
        } else if (id === '1') {
          //Shipper is Changed
          handleOrderChange(orderW, 'loadedOnSameTrailer', false);
        }

        const stops = prevState[orderW].stops!;

        const index = stops.findIndex(stop => stop.idW === id);
        stops[index] = {
          ...stops[index],
          [name]: value,
        };

        result = {
          ...prevState,
          ...result,
          [orderW]: {
            ...prevState[orderW],
            stops,
          },
        };
        return result;
      });
    },
    [handleOrderChange],
  );

  const handleAddLoadStop = useCallback((orderW: string) => {
    setLoadData(prevState => {
      let stopData = prevState[orderW].stops!;
      const stopNumberCount = prevState[orderW].stops!.length;
      const nextId =
        parseInt(prevState[orderW].stops![stopNumberCount - 1].idW, 10) + 1;
      const consigneeIndex = prevState[orderW].stops!.findIndex(
        stop => stop.stopType === LOAD_STOP_TYPE.DROPOFF && stop.isCustomer,
      );
      const updatedStop = {
        ...prevState[orderW].stops![consigneeIndex],
        stopNumber: stopNumberCount + 1,
      };

      stopData[consigneeIndex] = updatedStop;

      return {
        ...prevState,
        [orderW]: {
          ...prevState[orderW],
          stops: [
            ...stopData,
            {
              ...initialLoadStopState[1],
              idW: nextId.toString(),
              stopNumber: stopNumberCount,
              isCustomer: false,
            },
          ],
        },
      };
    });

    setOrderLegData(prevState => {
      const stateKeys = Object.keys(prevState);
      const stateValues = Object.values(prevState);
      let newKeyLeg = 1;
      let nextLegNumber = 1;
      if (stateKeys.length > 0 && stateValues.length > 0) {
        newKeyLeg = parseInt(stateKeys[stateKeys.length - 1], 10) + 1;
        nextLegNumber =
          stateValues.filter(val => val.orderW === orderW).length + 1;
      }

      setBillingData(prevState => {
        const stateKeys = Object.keys(prevState);
        let newKey = 1;
        if (stateKeys.length > 0) {
          newKey = parseInt(stateKeys[stateKeys.length - 1], 10) + 1;
        }
        return {
          ...prevState,
          [newKey]: {
            ...initialBillingState['1'],
            idW: newKey.toString(),
            legW: newKeyLeg.toString(),
            percentage: '',
          },
        };
      });
      return {
        ...prevState,
        [newKeyLeg]: {
          ...initialOrderLegsState[1],
          idW: newKeyLeg.toString(),
          orderW: orderW,
          legNumber: nextLegNumber,
        },
      };
    });
  }, []);

  const handleBillingChange = useCallback(
    (idW: string, name: string, value: any) => {
      setBillingData(prevState => ({
        ...prevState,
        [idW]: {
          ...prevState[idW],
          [name]: value,
        },
      }));
    },
    [],
  );

  const handleAccessorialChange = useCallback(
    (idW: string, name: string, value: any) => {
      setAccessorialData(prevState => ({
        ...prevState,
        [idW]: {
          ...prevState[idW],
          [name]: value,
        },
      }));
    },
    [],
  );

  const handleAddAccessorial = useCallback(() => {
    let nextAccessorialId: string;
    if (accessorials.length) {
      nextAccessorialId = `${parseInt(
        accessorials[accessorials.length - 1].idW,
      ) + 1}`;
    } else {
      nextAccessorialId = '1';
    }

    const billingDataLength = Object.keys(billingData).length;
    const accessorialLegs = Object.values(billingData).map(billing => ({
      legW: billing.legW,
      percentage: billingDataLength === 1 ? '100' : billing.percentage,
    }));

    setAccessorialData(prevState => ({
      ...prevState,
      [nextAccessorialId]: {
        ...initialAccessorialValue,
        idW: `${nextAccessorialId}`,
        legs: accessorialLegs,
      },
    }));
  }, [accessorials, billingData]);

  const handleRemoveAccessorial = useCallback((idW: string) => {
    setAccessorialData(prevState => {
      delete prevState[idW];
      return { ...prevState };
    });
  }, []);

  const handleRemoveOrder = useCallback((idW: string) => {
    setLoadData(prevState => {
      setOrderLegData(prevState => {
        const orderLegs = Object.entries(prevState);
        for (const [key, value] of orderLegs) {
          if (value.orderW === idW) {
            setBillingData(prevState => {
              const bill_delete = Object.values(prevState).find(
                billing => billing.legW === key,
              );
              if (bill_delete) {
                delete prevState[bill_delete.idW];
              }
              return { ...prevState };
            });

            setAccessorialData(prevState => {
              const accessorials = Object.values(prevState).map(accessorial => {
                const accLegs = accessorial.legs.filter(
                  leg => leg.legW !== key,
                );
                return {
                  ...accessorial,
                  legs: accLegs,
                };
              });

              let updateState = prevState;
              for (const accessorial of accessorials) {
                updateState[accessorial.idW!] = {
                  ...accessorial,
                };
              }
              return { ...prevState, ...updateState };
            });
            delete prevState[key];
          }
        }

        return { ...prevState };
      });

      const deleted_order = prevState[idW];
      const orders = Object.values(prevState).filter(
        order => order.orderNumber! > deleted_order.orderNumber!,
      );
      let updateState = prevState;

      for (const order of orders) {
        updateState[order.idW!] = {
          ...order,
          orderNumber: (parseInt(order.orderNumber!, 10) - 1).toString(),
        };
      }

      delete prevState[idW];
      return { ...prevState, ...updateState };
    });
  }, []);

  const handleRemoveLoadStop = useCallback((orderW: string, id: string) => {
    setLoadData(prevState => {
      const stopData = prevState[orderW].stops!;
      const deleted_stop = stopData.find(stop => stop.idW === id);
      let updatedStopData = [];
      for (const stop of stopData) {
        if (stop.idW === id) {
          continue;
        } else if (stop.stopNumber > deleted_stop!.stopNumber) {
          stop.stopNumber -= 1;
        }
        updatedStopData.push(stop);
      }

      setOrderLegData(prevState => {
        const orderLegs = Object.values(prevState).filter(
          orderLeg => orderLeg.orderW === orderW,
        );

        //Delete Largest Leg Number
        const deleted_order_leg = Object.values(orderLegs).find(
          orderLeg => orderLeg.legNumber === orderLegs.length,
        );

        setBillingData(prevState => {
          const bill_delete = Object.values(prevState).find(
            billing => billing.legW === deleted_order_leg!.idW,
          );
          if (bill_delete) {
            delete prevState[bill_delete.idW];
          }
          return { ...prevState };
        });

        setAccessorialData(prevState => {
          const accessorials = Object.values(prevState).map(accessorial => {
            const accLegs = accessorial.legs.filter(
              leg => leg.legW !== deleted_order_leg!.idW,
            );
            return {
              ...accessorial,
              legs: accLegs,
            };
          });

          let updateState = prevState;
          for (const accessorial of accessorials) {
            updateState[accessorial.idW!] = {
              ...accessorial,
            };
          }
          return { ...prevState, ...updateState };
        });

        // Clear all drivers for all affected leg

        let updateState = prevState;
        for (const orderLeg of orderLegs) {
          if (
            orderLeg.legNumber === deleted_stop!.stopNumber ||
            orderLeg.legNumber === deleted_stop!.stopNumber - 1
          ) {
            updateState[orderLeg.idW!] = {
              ...orderLeg,
              driver: '',
            };
          }
        }

        delete updateState[deleted_order_leg!.idW];

        return { ...updateState };
      });

      return {
        ...prevState,
        [orderW]: {
          ...prevState[orderW],
          stops: updatedStopData,
        },
      };
    });
  }, []);

  const handleClose = useCallback(() => {
    setFormOpen(false);
    onClose();
  }, [onClose]);

  const handleSubmit = async () => {
    // setLoadData(cloneDeep(initialOrderState));
    // setLoadStopData(initialLoadStopState);
    const orders = Array.from(Object.values(loadData)).map(load => {
      const newLoadQuantityUnit = {
        dimension: 'quantity',
        name: load.loadQuantityUnit,
      };
      const newLoadWeightUnit = {
        dimension: 'weight',
        name: load.loadWeightUnit,
      };
      const newLoadDistanceUnit = {
        dimension: 'distance',
        name: load.estimatedTravelDistanceUnit,
      };
      const newLoadType = load.loadType
        ? {
            name: load.loadType,
          }
        : null;
      const newEquipment = load.specialEquipment
        ? load.specialEquipment.split(',').map(e => ({
            name: e,
          }))
        : [];
      const newMode = Object.entries(MODE_OPTION_TEXT).find(
        ([key, value]) => value === load.mode,
      );

      const newDistance =
        (load.estimatedTravelDistance &&
          load.estimatedTravelDistance.length === 0) ||
        !load.estimatedTravelDistance
          ? null
          : load.estimatedTravelDistance;
      const stops = load.stops!.map(stop => {
        let newPickupWindow = {};
        let newDropoffWindow = {};
        if (!stop.isCustomer || stop.stopType === LOAD_STOP_TYPE.PICKUP) {
          newPickupWindow = {
            ...stop.pickupWindow,
            lower: stop.pickupWindow.lower!.format(),
            upper: stop.pickupWindow.upper!.format(),
          };
        }
        if (!stop.isCustomer || stop.stopType === LOAD_STOP_TYPE.DROPOFF) {
          newDropoffWindow = {
            ...stop.dropoffWindow,
            lower: stop.dropoffWindow.lower!.format(),
            upper: stop.dropoffWindow.upper!.format(),
          };
        }
        const newState = stop.state!.value;
        return {
          ...stop,
          state: newState,
          pickupWindow: newPickupWindow,
          dropoffWindow: newDropoffWindow,
        };
      });

      return {
        ...load,
        loadQuantityUnit: newLoadQuantityUnit,
        loadType: newLoadType,
        loadWeightUnit: newLoadWeightUnit,
        estimatedTravelDistance: newDistance,
        estimatedTravelDistanceUnit: newLoadDistanceUnit,
        specialEquipment: newEquipment,
        mode: newMode ? newMode[0] : '',
        stops: stops,
      };
    });

    let billingsModified = billings;
    if (billings.length === 1) {
      billingsModified = Object.values(billingData).map(billing => ({
        ...billing,
        percentage: '100',
      }));
    }
    const orderLegs = Array.from(Object.values(orderLegData)).map(orderLeg => {
      //Sending driver causes an error as there is a separate serializer for handling this
      delete orderLeg.driver;
      return {
        ...orderLeg,
      };
    });
    const accessorials = Object.values(accessorialData)
      .map(accessorial => {
        if (Object.values(omit(accessorial, ['idW'])).find(prop => !!prop)) {
          const newQuantity =
            (accessorial.quantity && accessorial.quantity.length === 0) ||
            !accessorial.quantity
              ? null
              : accessorial.quantity;
          const newAccessorial = {
            ...accessorial,
            quantity: newQuantity,
          };
          return {
            ...newAccessorial,
          };
        }
        return null;
      })
      .filter(accessorials => !!accessorials);

    const newBillTo = Object.entries(BILL_TO_TEXT).find(
      ([key, value]) => value === manifestData.billToOptions,
    );

    const billAsUnit = {
      dimension: 'weight',
      name: manifestData.billAsUnit,
    };

    const data = {
      ...manifestData,
      manifestNumber:
        manifestData.manifestNumber && updateManifestId
          ? manifestData.manifestNumber
          : nextManifestNumber,
      revenueTerminal: manifestData.revenueTerminal!.value,
      billToOptions: newBillTo ? newBillTo[0] : '',
      billAsUnit: billAsUnit,
      orders: orders,
      orderLegs: orderLegs,
      billingLegs: billingsModified,
      accessorials: accessorials,
    };

    // Check billing driver revenue sum.
    let sum = 0;
    const errorList: string[] = [];
    billingsModified.forEach(
      billing => (sum += parseFloat(billing.percentage)),
    );

    if (sum.toPrecision(3) !== '100') {
      errorList.push('Order Invoicing Driver Revenue does not add up to 100%');
    }

    //Check Accessorial driver revenue.
    Object.values(accessorialData).forEach((accessorial, index) => {
      let sum = 0;
      accessorial.legs.forEach(
        accLeg => (sum += parseFloat(accLeg.percentage)),
      );

      if (sum.toPrecision(3) !== '100') {
        errorList.push(
          `Accessorial # ${index + 1}  Driver Revenue does not add up to 100%`,
        );
      }
    });

    if (errorList.length) {
      return Promise.resolve({
        errors: {
          validateError: errorList,
        },
        ok: false,
      });
    }

    if (updateManifestId) {
      const result = await webStore.updateLoads(data);
      if (!result.ok) {
        let subsectionErrors: any[] = [];
        const sectionErrors: any[] = Object.values(result.errors);
        sectionErrors.forEach(e => {
          subsectionErrors.push(...e);
        });
        const errorVal: any[] = subsectionErrors.map(e => Object.values(e));

        errorList.push(...errorVal);
        return Promise.resolve({
          errors: {
            validateError: errorList,
          },
          ok: false,
        });
      }

      return result;
    } else {
      const result = await webStore.createLoads(data);
      if (!result.ok) {
        let subsectionErrors: any[] = [];
        const sectionErrors: any[] = Object.values(result.errors);
        sectionErrors.forEach(e => {
          subsectionErrors.push(...e);
        });
        const errorVal: any[] = subsectionErrors.map(e => Object.values(e));

        errorList.push(...errorVal);
        return Promise.resolve({
          errors: {
            validateError: errorList,
          },
          ok: false,
        });
      }

      return result;
    }
  };

  return (
    <Dialog
      aria-labelledby="manifest-builder-dialog-title"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open || formOpen}
    >
      <DialogTitle disableTypography className={classes.header}>
        <Typography
          className={classes.title}
          id="manifest-builder-dialog-title"
          variant="h2"
        >
          Manifest Builder
        </Typography>
        {!isSuccess && (
          <div>
            <Button className={classes.resetBtn} onClick={handleReset}>
              Reset
            </Button>
            <IconButton
              aria-label="cancel"
              className={classes.resetBtn}
              onClick={handleFormClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </DialogTitle>
      <DialogContent className={classes.main}>
        {isLoadLoading ? (
          <div className={classes.loadingWrap}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <ManifestBuilderForm
            manifestNumber={
              manifestData.manifestNumber && updateManifestId
                ? manifestData.manifestNumber
                : nextManifestNumber
            }
            manifest={manifestData}
            loadTypes={loadTypeChoices}
            locations={locationChoices}
            distanceUnits={distanceUnitChoices}
            quantityUnits={quantityUnitChoices}
            weightUnits={weightUnitChoices}
            loads={loads}
            // loadStops={loadStops}
            orderLegs={orderLegs}
            billings={billings}
            accessorials={accessorials}
            onClose={handleFormClose}
            onManifestChange={handleManifestChange}
            onBillingChange={handleBillingChange}
            onOrderChange={handleOrderChange}
            onAddOrder={handleAddOrder}
            onRemoveOrder={handleRemoveOrder}
            onLoadStopChange={handleLoadStopChange}
            onLoadStopAdd={handleAddLoadStop}
            onLoadStopRemove={handleRemoveLoadStop}
            onAccessorialChange={handleAccessorialChange}
            onAddAccessorial={handleAddAccessorial}
            onRemoveAccessorial={handleRemoveAccessorial}
            onSubmit={handleSubmit}
            setSuccess={setIsSuccess}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    backgroundColor: colors.textPrimary[400],
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: 'white',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  resetBtn: {
    color: 'white',
    marginLeft: theme.spacing(3),

    '&:hover': {
      backgroundColor: colors.textPrimary[600],
      textDecoration: 'underline',
    },
  },
  main: {
    padding: theme.spacing(2, 0, 0),
  },
  loadingWrap: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 500,
  },
}));

export default observer(ManifestBuilderDialog);
