import {
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStores } from 'doc-mate-store/lib/hooks';
import { defaultRootStore } from 'doc-mate-store/lib/models/Store';
import * as api from 'doc-mate-store/lib/services/api';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useMemo, useState } from 'react';
import DriverAssignConfirmation from './DriverAssignConfirmation';
import { DriverDetails, DriverOption } from './types';
type Props = {
  driverName: string;
  driverId: string;
  loadId: number;
  isDispatcher: boolean;
  linkedLegs: number[];
};

const LoadDriverCellFields: React.FC<Props> = props => {
  const { driverName, driverId, loadId, isDispatcher, linkedLegs } = props;

  const [error, setError] = useState<string | null>(null);
  const [isHovering, setIsHovering] = useState<boolean>(false); //eslint-disable-line
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [newDriver, setNewDriver] = useState<DriverOption | null>(null);
  const [driverDetails, setDriverDetails] = useState<DriverDetails | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { rootStore } = useStores();
  const classes = useStyles();

  // useEffect(() => {
  //   if (driverId) {
  //     loadDriverInfo(driverId);
  //   }
  // }, [driverName, driverId]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setIsHovering(false);
    event.stopPropagation();
  }, []);

  const loadDriverInfo = useCallback(async (driverId: string) => {
    const { token } = defaultRootStore;
    let entities: DriverDetails | undefined = undefined;
    if (!driverId) {
      setDriverDetails(null);
      return;
    }
    try {
      ({
        response: { entities },
      } = (await api.fetchDriverInfo(token!, driverId)) as any);
    } catch (error) {
      console.log('[DEBUG] error', (error as Error).message);
    }

    if (entities) {
      setDriverDetails(entities);
    } else {
      setDriverDetails(null);
    }
  }, []);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<{}>, value: DriverOption | null) => {
      event.stopPropagation();
      setError(null);
      if (value) {
        const newValue = value.value as string;
        if (newValue !== driverId) {
          const load = rootStore.loadTableRows.get(`${loadId}`);
          if (load) {
            if (linkedLegs.length > 0) {
              setOpenConfirm(true);
              setNewDriver(value);
              return;
            }
            const response = await load.assignDriver(newValue);
            if (newValue) {
              await loadDriverInfo(newValue);
            }
            if (response && !response.ok) {
              if (
                response.errors &&
                response.errors.driver &&
                response.errors.driver.length > 0
              ) {
                setError(response.errors.driver[0]);
              }
            }
            setIsOpen(false);
            setOpenConfirm(false);
            setIsLoading(false);
            setNewDriver(null);
          }
        }
      }
    },
    [
      driverId,
      linkedLegs.length,
      loadDriverInfo,
      loadId,
      rootStore.loadTableRows,
    ],
  );

  const { users: users_ } = rootStore;
  const users = Array.from(users_.values()).filter(user => user.isDriver);
  const usersJ = JSON.stringify(users);
  const drivers = useMemo(() => {
    let options: DriverOption[] = users.map(user => ({
      name: `${user.lastName}, ${user.firstName}`,
      value: user.getRefId(),
      organization: user.organization?.getRefId(),
      organizationName: user.organizationName,
    }));
    // if (driverId && driverName) {
    //   options = options.concat({
    //     name: driverName,
    //     value: driverId,
    //     organizationName: '',
    //   });
    // }
    options = options.concat({
      name: 'No driver assigned',
      value: '',
      organizationName: '',
    });
    options.sort((a, b) => {
      if (a.name === 'No driver assigned') return -1;
      const organizationSorter = a.organizationName
        .toLowerCase()
        .localeCompare(b.organizationName.toLowerCase());
      const nameSorter = a.name
        .toLowerCase()
        .localeCompare(b.name.toLowerCase());
      return organizationSorter || nameSorter;
    });
    return uniqBy(options, v => [v.name, v.organizationName].join());
  }, [usersJ, driverId, driverName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetOptionSelected = useCallback(
    (option: DriverOption, selected: DriverOption) =>
      option.value === selected.value,
    [],
  );

  const handleConfirm = useCallback(async () => {
    const load = rootStore.loadTableRows.get(`${loadId}`);
    setIsLoading(true);
    if (load) {
      const response = await load.assignDriver(newDriver!.value);
      if (newDriver) {
        await loadDriverInfo(newDriver!.value);
      }
      if (response && !response.ok) {
        if (
          response.errors &&
          response.errors.driver &&
          response.errors.driver.length > 0
        ) {
          setError(response.errors.driver[0]);
        }
      }
      setIsOpen(false);
      setOpenConfirm(false);
      setIsLoading(false);
      setNewDriver(null);
    }
  }, [loadDriverInfo, loadId, newDriver, rootStore.loadTableRows]);

  const handleCancel = useCallback(async () => {
    setOpenConfirm(false);
    setIsOpen(false);
    setNewDriver(null);
  }, []);

  const handleMouseEnter = useCallback(async () => {
    if (driverId && !driverDetails) {
      loadDriverInfo(driverId);
    }
    if (!isOpen && !!driverId) {
      setIsHovering(true);
    }
  }, [driverDetails, driverId, isOpen, loadDriverInfo]);

  const handleMouseLeave = useCallback(() => {
    if (!isOpen) {
      setIsHovering(false);
    }
  }, [isOpen]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
    setIsHovering(false);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setIsHovering(false);
  }, []);

  const getOptionLabel = useCallback(
    (option: { name: any }) => option.name,
    [],
  );
  const renderInput = useCallback(
    (params: JSX.IntrinsicAttributes & TextFieldProps) => (
      <TextField {...params} className={classes.select} />
    ),
    [classes.select],
  );
  const driverValue = useMemo(
    () => ({
      name: driverName,
      value: driverId,
      organizationName: '',
    }),
    [driverId, driverName],
  );
  const popUpIcon = useMemo(
    () => (isDispatcher ? <ArrowDropDownIcon /> : null),
    [isDispatcher],
  );

  return (
    <>
      <FormControl
        error={!!error}
        fullWidth
        onMouseOut={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        <Autocomplete
          className={classes.dropdown}
          fullWidth
          getOptionSelected={handleGetOptionSelected}
          options={drivers}
          getOptionLabel={getOptionLabel}
          renderInput={renderInput}
          selectOnFocus
          blurOnSelect
          disableClearable
          disabled={!isDispatcher}
          popupIcon={popUpIcon}
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          onClick={handleClick}
          value={driverValue}
          groupBy={options => options.organizationName}
          filterOptions={(options, { inputValue }) =>
            options.filter(
              item =>
                item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                (item.organizationName &&
                  item.organizationName
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())),
            )
          }
        />
        {error && (
          <FormHelperText className={classes.error}>{error}</FormHelperText>
        )}

        {/* {isHovering && !isOpen && driverDetails && (
          <DriverInfo details={driverDetails} />
        )} */}

        {!isOpen && openConfirm && (
          <DriverAssignConfirmation
            loading={isLoading}
            legId={loadId}
            driver={newDriver!.name}
            legInfoList={linkedLegs}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </FormControl>
    </>
  );
};

const useStyles = makeStyles(() => ({
  select: {
    backgroundColor: 'transparent',
    '&&&& input': {
      paddingLeft: 5,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  dropdown: {
    '& .Mui-focused': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
      backgroundColor: 'white',
      borderRadius: 4,
    },
  },
  driverInfo: {
    width: 450,
    backgroundColor: '#155878',
    position: 'absolute',
    left: 0,
    marginLeft: '75%',
    color: 'white',
    padding: '29px 30px',
    borderRadius: 5,
    'z-index': 10,
    '& img': {
      height: 50,
      width: 50,
      borderRadius: 25,
    },
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
  content: {
    paddingTop: 10,
    paddingBottom: 30,
  },
}));

export default React.memo(LoadDriverCellFields);
