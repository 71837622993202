import {
  ON_ASSIGNMENT_STATUS_FROM_API,
  ON_TIME_STATUS_FROM_API,
} from 'doc-mate-store/lib/constants/load';
import { LoadTableRow } from 'doc-mate-store/lib/models';
import { toAPI } from 'doc-mate-store/lib/models/LoadQuery';
import { isEqual } from 'lodash';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { Column } from 'react-table';
import { useWebStores } from '.';
import PresetTables, { presetFiltersFromAPI } from '../models/PresetTables';
import { SavedTable } from '../models/SavedTable';
import {
  Filter,
  FilterAndColumn,
  FilterFieldWithHeader,
  FilterType,
  FilterValue,
} from '../types/filtering';

function getInitialValue(type: FilterType) {
  switch (type) {
    case 'text':
      return '';
    case 'select':
      return '';
    case 'selectMultiple':
      return [];
    case 'date':
      return '';
  }
  return '';
}

function useFiltersWithState(
  filters: Filter[],
  onFilterChange: (filterValues: { [k: string]: FilterValue }) => void,
  savedTable?: SavedTable,
  preset?: string,
  presetTables?: PresetTables,
) {
  const history = useHistory();
  const { webStore } = useWebStores();

  const filtersByAccessor: { [k: string]: Filter } = filters.reduce(
    (acc, val) => ({
      ...acc,
      [val.accessor]: val,
    }),
    {},
  );

  // State management and default values

  const state: { [k: string]: FilterValue } = useMemo(
    () =>
      savedTable
        ? savedTable.filtersFromAPI
        : preset && presetTables && presetTables[preset]
        ? presetFiltersFromAPI(presetTables, preset)
        : {},
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      preset,
      presetTables,
      savedTable && (savedTable.filtersFromAPI || savedTable.visible),
    ],
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  useEffect(() => {
    onFilterChange(state);
  }, [onFilterChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateFilters = useCallback(() => {
    onFilterChange(state);
    if (savedTable) {
      savedTable.save();
    }
  }, [onFilterChange, savedTable, state]);

  const prevPresetOrSavedTableId = useRef<any>(null);
  useEffect(() => {
    const presetOrSavedTableId = savedTable ? `${savedTable.id}` : preset;
    webStore.setSelectedStatus(preset || '');
    if (!isEqual(prevPresetOrSavedTableId.current, presetOrSavedTableId)) {
      onFilterChange(state);
      prevPresetOrSavedTableId.current = presetOrSavedTableId;
    }
  }, [onFilterChange, preset, savedTable, state, webStore]);

  // Filter visibility

  const availableFilters = useMemo(
    () => filters.map(filter => filter.accessor),
    [filters],
  );

  const visibleFilters: string[] = savedTable
    ? savedTable.visible.filter(filter => availableFilters.indexOf(filter) >= 0)
    : preset && presetTables && presetTables[preset]
    ? Object.keys(presetTables[preset].filters)
    : [];

  // Generic getter and setter

  const getValue = (accessor: string) => {
    const value =
      (state[accessor] as FilterValue) ||
      getInitialValue(filtersByAccessor[accessor].type);
    return value;
  };

  const setValue = (accessor: string) => (value: FilterValue) => {
    if (savedTable) {
      savedTable.setFilter(accessor, value);
    }
  };

  const deleteValue = (accessor: string) => {
    if (savedTable) {
      savedTable.deleteFilter(accessor);
    }
  };

  const clearValues = () => {
    if (savedTable) {
      savedTable.clearFilters();
    }
  };

  const setValues = useCallback(
    (savedTable?: SavedTable, preset?: string) => {
      if (savedTable) {
        const nextQuery: { [k: string]: FilterValue } = {
          table: savedTable.id,
        };
        history.push(`?${qs.stringify(toAPI(nextQuery))}`);
      } else if (preset && ON_TIME_STATUS_FROM_API[preset]) {
        history.push(`?table=${preset}`);
      } else if (preset && ON_ASSIGNMENT_STATUS_FROM_API[preset]) {
        history.push(`?table=${preset}`);
      } else if (preset && preset === 'all') {
        history.push(`?table=${preset}`);
      } else if (preset && preset === 'rejected') {
        history.push(`?table=rejected`);
      } else {
        history.push('?table=');
      }
    },
    [history],
  );

  return {
    savedTable,
    preset,
    getValue,
    setValue,
    deleteValue,
    clearValues,
    setValues,
    visibleFilters,
    handleUpdateFilters,
  };
}

export function useFilters(
  filterFields: FilterFieldWithHeader<LoadTableRow>[],
  onFilterChange: (filterValues: { [k: string]: FilterValue }) => void,
  savedTable?: SavedTable,
  preset?: string,
  presetTables?: PresetTables,
) {
  const filters = useMemo(
    () =>
      filterFields.map(({ Header, ...rest }) => ({
        label: Header,
        ...rest,
      })) as Filter[],
    [filterFields],
  );

  const {
    getValue,
    setValue,
    deleteValue,
    clearValues,
    setValues,
    visibleFilters,
    handleUpdateFilters,
  } = useFiltersWithState(
    filters,
    onFilterChange,
    savedTable,
    preset,
    presetTables,
  );

  const editable = !!savedTable;

  return {
    filters,
    editable,
    getValue,
    setValue,
    deleteValue,
    clearValues,
    setValues,
    visibleFilters,
    handleUpdateFilters,
  };
}

export function useColumns(
  filterAndColumns: FilterAndColumn<LoadTableRow>[],
): Column<LoadTableRow>[] {
  const columns = useMemo(
    () =>
      filterAndColumns
        .filter(item => !!item.column)
        .map(({ Header, column }) => ({
          Header,
          ...column,
        })),
    [filterAndColumns],
  );
  return columns;
}

export function useFilterFields(
  filterAndColumns: FilterAndColumn<LoadTableRow>[],
) {
  const filterFields = useMemo(
    () =>
      filterAndColumns
        .filter(item => !!item.filtering)
        .map(({ Header, filtering }) => ({
          Header,
          ...filtering,
        })) as FilterFieldWithHeader<LoadTableRow>[],
    [filterAndColumns],
  );
  return filterFields;
}

export function useDefaultColumnOrdering(
  filterAndColumns: FilterAndColumn<LoadTableRow>[],
) {
  const columnOrdering = useMemo(
    () =>
      filterAndColumns
        .filter(item => item.column && item.column.show)
        .map(item => (item.column as Column).id!),
    [filterAndColumns],
  );
  return columnOrdering;
}
